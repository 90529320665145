/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Vvoden from "@components/sharedComponents/customersLogos/logos/Vvoden.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import E4CC from "@components/sharedComponents/customersLogos/logos/E4CC.svg"
import Farenet from "@components/sharedComponents/customersLogos/logos/Farenet.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/plataforma-score.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/soluciones-telefonicas-para-empresas.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/software-cloud-pbx.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/chatgpt-contact-center.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/indicadores-de-productividad.jpg"


// logos testimonios
import CulqiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import SynlabTes from "@components/sharedComponents/testimonies/images/logos/synlab.svg"
import IttsaBusTes from "@components/sharedComponents/testimonies/images/logos/ittsaBus.svg"
import EnseñaTes from "@components/sharedComponents/testimonies/images/logos/enseña.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import synlabDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/synlab.png"
import ittsaBusDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ittsaBus.png"
import enseñaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/enseña.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import synlabMobile from "@components/sharedComponents/testimonies/images/fotosMobile/synlab.png"
import ittsaBusMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ittsaBus.png"
import enseñaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/enseña.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageScore/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageScore/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageScore/images/accordionLeft/imageThree.webp"

//image acordeon right
import ImageAccordionRightOne from "@components/pageScore/images/accordionRight/imageOne.webp"
import ImageAccordionRightTwo from "@components/pageScore/images/accordionRight/imageTwo.webp"
import ImageAccordionRightThree from "@components/pageScore/images/accordionRight/imageThree.webp"
import ImageAccordionRightFour from "@components/pageScore/images/accordionRight/imageFour.webp"
import ImageAccordionRightFive from "@components/pageScore/images/accordionRight/imageFive.webp"

//image indrustias
import banca from "@components/pageScore/industries/images/banca.png"
import salud from "@components/pageScore/industries/images/salud.png"
import retail from "@components/pageScore/industries/images/retail.png"
import educacion from "@components/pageScore/industries/images/educacion.png"
import bop from "@components/pageScore/industries/images/bop.png"

let data = {
  start: {
    textbody:
      "Hacemos que las empresas se contacten y comuniquen mejor con sus clientes, con soluciones omnicanales y tecnologías de voz. Aplicamos para todos, grandes operaciones de Contact Center y negocios que buscan escalar.",
    image: "./banner.html",
    textbutton: "Hablar con un experto",
    textlink: "Registrarme a una demo Beex",
    link: "/contact-center/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Alfin,
    Prestamype,
    Civa,
    Synlab,
    Vvoden,
    Conexo,
    Eriza,
    E4CC,
    Farenet
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Alfin, Prestamype, Civa,],
    arrayTwo: [Synlab, Vvoden, Conexo, Eriza, E4CC, Farenet],
  },

  articles: [
    {
      image: article1,
      title: "Beex Contact Center: qué es y cómo funciona",
      textbody:
        "Descubre qué es esta plataforma y cómo puede elevar la productividad y la calidad de tus interacciones con tus clientes.",
      link: "https://beexcc.com/blog/plataforma-score/",
      card: true,
    },
    {
      image: article2,
      title: "10 soluciones telefónicas que debes conocer en el 2024",
      textbody:
        "Conoce 10 soluciones telefónicas para empresas con esta lista de buenas opciones.",
      link: "https://beexcc.com/blog/soluciones-telefonicas-para-empresas/",
      card: true,
    },
    {
      image: article3,
      title: "Top 10 de Software Cloud PBX para empresas en el 2024",
      textbody:
        "Eleva la eficiencia de tu Contact Center con esta selección de los 10 mejores Software Cloud PBX.",
      link: "https://beexcc.com/blog/software-cloud-pbx/ ",
    },
    {
      image: article4,
      title: "¿Cómo usar ChatGPT para Contact Center? 5 casos",
      textbody:
        "Descubre 5 casos de uso de ChatGPT para Contact Center y ofrece a tus clientes una atención de primer nivel.",
      link: "https://beexcc.com/blog/chatgpt-contact-center/",
    },
    {
      image: article5,
      title: "10 indicadores de productividad en un Contact Center ",
      textbody:
        "Conoce 10 indicadores de productividad para elevar la eficiencia de tu Contact Center, desde la gestión de tiempo hasta la calidad de atención.",
      link: "https://beexcc.com/blog/indicadores-de-productividad/",
    },
  ],
  contacts: {
    title: "Empieza a mejorar la comunicación con tus clientes, ahora mismo",
    textbody:
      "Cuéntanos mejor sobre tu operación y te compartiremos feedback importante. Conoce porque Beex es la solución ideal para tu empresa y que resultados puedes lograr.",
    href: "/hablemos/",
    btn: " Comunícate con ventas",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CulqiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: NetcallTes,
      title:
        "Con Beex y su marcador predictivo, hemos duplicado nuestras ventas mensuales",
      textbody:
        "Netcall Perú, BPO especializado en brindar soluciones al sector telecomunicaciones, ha automatizado procesos operativos, como backoffice, según sus reglas de negocio.",
      name: "Humberto Chumán",
      post: "Jefe de Proyectos",
      link: "/casos-de-exito/netcall-peru/",
      imgDesktop: netcallDesktop,
      imgMobile: netcallMobile,
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SynlabTes,
      title:
        "Beex nos ha permitido resolver el 20% de  atenciones sin necesidad de agentes",
      textbody:
        "SYNLAB Perú, operación Peruana del laboratorio clínico más grande de Europa, ha mejorado su tiempo de primera respuesta en 95% gracias a las automatizaciones de Beex.",
      name: "Yesenia Sánchez Rodríguez",
      post: "Jefa de Servicio al Cliente",
      link: "/casos-de-exito/synlab/",
      imgDesktop: synlabDesktop,
      imgMobile: synlabMobile,
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoró su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "20%",
          descripcion: "tickets cerrados sin necesidad de un agente",
          color: "#5831D8",
        },
        {
          metric: "20 seg",
          descripcion: "disminuyeron sus respuestas telefónicas",
          color: "#00CA56",
        },
      ],
    },
    {
      image: IttsaBusTes,
      title:
        "Con Beex, mejoramos en 22% la satisfacción de nuestros usuarios al reagendar sus viajes",
      textbody:
        "IttsaBus, empresa de transporte terrestre, automatiza sus procesos de atención al cliente y marketing con Beex, mejorando sus métricas más importantes de satisfacción.",
      name: "Iris Pérez Medina",
      post: "Coordinadora de Marketing y Servicio al Cliente",
      link: "/casos-de-exito/ittsa-bus/",
      imgDesktop: ittsaBusDesktop,
      imgMobile: ittsaBusMobile,
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoraron su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "22%",
          descripcion: "mejoraron satisfacción en reprogramación de viajes",
          color: "#5831D8",
        },
      ],
    },
    {
      image: EnseñaTes,
      title:
        "Con Beex, hemos aumentado en +50% la contactabilidad con potenciales donantes",
      textbody: "Enseña Perú ha logrado aumentar en +40% la cantidad de donantes a su movimiento social que transforma la educación de los niños más necesitados en el país.",
      name: "Omar García",
      post: "Coordinador del Programa de Suscriptores",
      link: "/casos-de-exito/ensena-peru/",
      imgDesktop: enseñaDesktop,
      imgMobile: enseñaMobile,
      metrics: [
        {
          metric: "40%",
          descripcion: "aumentaron sus donantes",
          color: "#FFB800",
        },
        {
          metric: "+50%",
          descripcion: "mejoró su contactabilidad",
          color: "#5831D8",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionLeft: [
    {
      title: "Vista omnicanal",
      description: "<p>Centraliza tus principales canales de comunicación, como llamadas, WhatsApp, redes sociales y correo. Obtén una vista 360 de tus clientes y sus interacciones, automatizando acciones que logren mejorar tus tiempos de respuesta y niveles de servicio.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "IA y workflows",
      description: "<p>Crea automatizaciones para servicio al cliente, telemarketing o ventas. Usa nuestra integración con ChatGPT para ayudar mejor a tus clientes, prospectar eficientemente a tus leads o automatizar tus ventas. Diseña flujos automatizados en minutos con nuestros workflows.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones",
      description: "<p>Ya sea que gestiones con Salesforce, HubSpot, Zoho, ZAP u otros. Beex se integra con tus aplicaciones favoritas para automatizar acciones por eventos personalizados, o para armar una vista centralizada de tus clientes. Goza de una API flexible, potente y escalable.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  accordionRight: [
    {
      title: "Soporte al cliente con chatbots e IVR",
      description: "<p>Diseña e implementa agentes virtuales por tu canal de voz y chats. Con los workflows de Beex y sus funcionalidades de IA, podrás implementar autoservicio en tu operación sin necesidad de codificar. Automatiza tu servicio al cliente y mejora tus tiempos de respuesta y resolución.</p>",
      image: ImageAccordionRightOne
    },
    {
      title: "Contacto automatizado con leads",
      description: "<p>Utiliza acciones combinadas por diferentes canales para mejorar tu conversión. Crea automatizaciones de primer y segundo contacto, por teléfono, WhatsApp y más. Mejora los resultados de tus campañas de marketing asegurando que tus leads tengan respuesta cuando ellos lo necesitan.</p>",
      image: ImageAccordionRightTwo
    },
    {
      title: "Campañas telefónicas en frío",
      description: "<p>Para tus operaciones de televentas, usa las marcaciones progresivas y predictivas de Beex. Asegura el mayor porcentaje de contactabilidad con tus prospectos con su marcación con Machine Learning. Ideal para Contact Center y BPOs de ventas.</p>",
      image: ImageAccordionRightThree,
    },
    {
      title: "Cobros telefónicos con IVR o vía SMS",
      description: "<p>Si eres un Contact Center, o una empresa con una operación de finanzas interna, mejora el contacto con tus clientes y asegura el pago a tiempo. Para cobros preventivos, mora temprana o cartera castigada, usa las automatizaciones de contacto por llamada, WhatsApp, SMS o email.</p>",
      image: ImageAccordionRightFour,
    },
    {
      title: "Encuestas telefónicas o por WhatsApp",
      description: "<p>Crea encuestas de satisfacción en Beex y, luego de una llamada o en el momento que desees a través de WhatsApp, envíalas a tus clientes u obtén feedback importante. Usa los datos de tus encuestas con nuestra analítica y aplica acciones de mejora con nuestros workflows e IA.</p>",
      image: ImageAccordionRightFive,
    },
  ],
  industries: [
    {
      tab: "01. Banca y Seguros",
      title: "Contact Center Software para Bancos, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Otorga las mejores funcionalidades de comunicación a tus agentes para que brinden asistencia a tus clientes y gestiones de forma inteligente los reclamos y solicitudes. En el menor tiempo posible, los 365 días del año.
          <br /> <br />
          Comunicación con protocolos de seguridad para tus canales de voz y digitales, donde compartes información sensible que involucran pagos en línea. Personalízala con integraciones seguras.
          <br /> <br />
        </p>
      ),
      image: banca,
      active: "container-industries-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Conoce Beex para Banca y Seguros",
    },
    {
      tab: "02. Educación",
      title:
        "Contact Center Software para Universidades, Institutos y colegios",
      text: (
        <p>
          <br />
          Automatiza y mejora los procesos en tus épocas de matrículas. Haz que tus números entrantes y canales digitales sincronicen información para brindar información precisa a tus estudiantes.
          <br /> <br />
          Otorga soporte por los canales en donde están realmente tus estudiantes, como WhatsApp. Crea chatbots 24x7 para ayudarlos con consultas acerca de tus horarios, campus y más.
          <br /> <br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Conoce Beex para Educación",
    },
    {
      tab: "03. Retail",
      title: "Contact Center Software para Retail y Bienes de Consumo",
      text: (
        <p>
          <br />
          Brinda la mejor experiencia de compra en digital para comercio minorista. Atiende reclamos o consultas por canal de voz o chat. Fideliza realmente con tus clientes y mejora tu NPS y CLV.
          <br /><br />
          Potencia tu equipo de atención al cliente o ventas, otorgándoles funcionalidades que mejoren la comunicación con tus clientes. Usa colaboración de casos entre equipos y mide tus KPIs en tiempo real.
          <br /> <br />
        </p>
      ),
      image: retail,
      link: "/hablemos/",
      textLink: "Conoce Beex para Retail",
    },
    {
      tab: "04. Salud",
      title: "Contact Center Software para Clínicas y Hospitales",
      text: (
        <p>
          <br />
          Brinda una atención diferenciada a tus pacientes. Ya seas una clínica privada o un hospital público, con Beex lograrás optimizar las citas, consultas, reclamos y atenciones en general.
          <br /><br />
          Automatiza tus procesos de citas y notificaciones automáticas por WhatsApp. Además, crea un autoservicio que se adecúe a los diferentes rangos de edades de tus pacientes.
          <br /> <br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Conoce Beex para Salud",
    },
    {
      tab: "05. BPO y tercerizadores",
      title: "Contact Center Software para BPOs, Contact y Call Centers",
      text: (
        <p>
          <br />
          Administra tus campañas entrantes o salientes bajo un único software de Contact Center. Ya sea que cuentes con clientes que te confíen sus bases de banca, seguros, automotriz, retail o gobierno.
          <br /><br />
          Potencia tus operaciones de televentas o cobranzas con marcadores telefónicos, IVR inteligentes, módulos de calidad y auditoría, procesos de backoffice, reportería y supervisión en tiempo real.
          <br /> <br />
        </p>
      ),
      image: bop,
      link: "/hablemos/",
      textLink: "Conoce Beex para BPO y tercerizadores",
    },
  ],

}

export default data
